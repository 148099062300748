jQuery(document).ready(function($){
    //if you change this breakpoint in the style.css file (or _layout.scss if you use SASS), don't forget to update this value as well
    var MqL = 1200;

    setLanguageUrls();

    $(window).on('scroll touchmove', function(event) {
        $('header').toggleClass('body-is-scrolled', $(document).scrollTop() > 0);
    });



    //mobile - open lateral menu clicking on the menu icon
    $('.nav-trigger').on('click', function(event){
        event.preventDefault();
        if( $('.content').hasClass('nav-is-visible') ) {
            closeNav();
            $('.overlay').removeClass('is-visible');
        } else {
            $(this).addClass('nav-is-visible');
            $('.cd-primary-nav').addClass('nav-is-visible');
            $('.header').addClass('nav-is-visible');
            $('.content').addClass('nav-is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
                $('body').addClass('overflow-hidden');
            });
            $('.overlay').addClass('is-visible');
        }
    });

    //close lateral menu on mobile
    $('.overlay').on('swiperight', function(){
        if($('.cd-primary-nav').hasClass('nav-is-visible')) {
            closeNav();
            $('.overlay').removeClass('is-visible');
        }
    });

    $('.overlay').on('click', function(){
        closeNav();
        $('.overlay').removeClass('is-visible');
    });


    //prevent default clicking on direct children of .cd-primary-nav
    $('.cd-primary-nav').children('.has-children').children('a').on('click', function(event){
        event.preventDefault();
    });
    //open submenu
    $('.has-children').children('a').on('click', function(event){
        if( !checkWindowWidth() ) event.preventDefault();
        var selected = $(this);
        if( selected.next('ul').hasClass('is-hidden') ) {
            //desktop version only
            selected.addClass('selected').next('ul').removeClass('is-hidden').end().parent('.has-children').parent('ul').addClass('moves-out');
            selected.parent('.has-children').siblings('.has-children').children('ul').addClass('is-hidden').end().children('a').removeClass('selected');
            $('.overlay').addClass('is-visible');
        } else {
            selected.removeClass('selected').next('ul').addClass('is-hidden').end().parent('.has-children').parent('ul').removeClass('moves-out');
            $('.overlay').removeClass('is-visible');
        }
    });

    //submenu items - go back link
    $('.go-back').on('click', function(){
        $(this).parent('ul').addClass('is-hidden').parent('.has-children').parent('ul').removeClass('moves-out');
    });

    function closeNav() {
        $('.nav-trigger').removeClass('nav-is-visible');
        $('.header').removeClass('nav-is-visible');
        $('.cd-primary-nav').removeClass('nav-is-visible');
        $('.has-children ul').addClass('is-hidden');
        $('.has-children a').removeClass('selected');
        $('.moves-out').removeClass('moves-out');
        $('.content').removeClass('nav-is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
            $('body').removeClass('overflow-hidden');
        });
    }

    function checkWindowWidth() {
        //check window width (scrollbar included)
        var e = window,
            a = 'inner';
        if (!('innerWidth' in window )) {
            a = 'client';
            e = document.documentElement || document.body;
        }
        if ( e[ a+'Width' ] >= MqL ) {
            return true;
        } else {
            return false;
        }
    }

    function setLanguageUrls() {
        currentUrl = window.location.href;
        enButton = $("#enButton");
        nlButton = $("#nlButton");

        enUrl = currentUrl.replace("/nl/", "/en/");
        enButton.attr("href", enUrl);

        nlUrl = currentUrl.replace("/en/", "/nl/");
        nlButton.attr("href", nlUrl);
    }
});