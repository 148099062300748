function showToast(type, message) {
    var div = $("<div>").addClass("toast " + type);
    var p = $("<p>").html(message);
    div.append(p);
    div.insertBefore($("main"));

    setTimeout(function () {
        div.addClass("out");
        setTimeout(function () {
            div.remove();
        }, 350)
    }, 5000);
}